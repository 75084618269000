////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// Login Password Show Button ///////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$(function () {
    // show password input value
    $(".password-addon").on("click", function () {
        $(this).find("i").toggleClass("fa-eye").toggleClass("fa-eye-slash");
        if ($(this).siblings("input").length > 0) {
            $(this).siblings("input").attr("type") === "password"
                ? $(this).siblings("input").attr("type", "input")
                : $(this).siblings("input").attr("type", "password");
        }
    });
});

const forms = document.getElementsByTagName("form");
for (let i = 0; i < forms.length; i++) {
    disableSubmitButtonOnSubmit(forms[i]);
}

// Funktion, um den Absende-Button zu deaktivieren
function disableSubmitButtonOnSubmit(form) {
    form.addEventListener(
        "submit",
        function (event) {
            if ($(event.submitter).attr("formnovalidate")) {
                return;
            }

            // Validierung des Formulars
            if (checkSubmitted(form)) {
                // Verhindern der Standard-Formularübermittlung, wenn die Validierung fehlschlägt
                event.preventDefault();
                event.stopPropagation();
            } else {
                // Deaktiviere den Absende-Button
                const submitButton = form.querySelector('[type="submit"]');
                if (submitButton) {
                    submitButton.disabled = true;
                }
            }
            form.classList.add("submitted");
        },
        false
    );
}

function checkSubmitted(form) {
    return !!form.classList.contains("submitted");
}
